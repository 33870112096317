import { useEffect, useState } from "react";

import { getFunctions, httpsCallable } from "firebase/functions";
import API_ROUTE from "ApiRoute";
import axios from "axios";
import { useUser } from "contexts/UserContext";
import { doc, getFirestore } from "firebase/firestore";
import { useDocumentData, useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { Handout } from "components/handout/Handout";
import { useQuarterId } from "hooks/UseQuarterId";
import { dateToWeekIndex } from "./MusicUtil";
import { Quote, SongTitle } from "./components/SongStyles";
import { SongLink } from "./components/SongLink";
import styled from "styled-components";
import Swal from "sweetalert2";
import { ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
const functions = getFunctions();

const N_TODO = 4

export const EliminationRoundVoting = ({ round }) => {
  const qtrId = useQuarterId()
  return <Handout element={<>
  <h1>CS109 Song: Elimination Round 1</h1>
  <EliminationRoundInner round={round} />

  </>} />
}

const EliminationRoundInner = ({ round }) => {
  const roundId = `round${round}`

  const db = getFirestore();
  const qtrId = useQuarterId();
  const userId = useUser().uid
  const [roundVotes, allVotesLoading] = useDocumentData(doc(db, `music/${qtrId}/public/elimination/votes/${roundId}`));
  const [eliminationData, eliminationDocLoading] = useDocumentDataOnce(doc(db, `music/${qtrId}/public/elimination`));
  const [songData, songDataLoading] = useDocumentDataOnce(doc(db, `music/${qtrId}/public/songs`));

  if (allVotesLoading || songDataLoading || eliminationDocLoading) {
    return <></>
  }
  const nDone = getNumRated(roundVotes, userId)

  const bracketToRate = getBracketToRate(roundVotes, userId)


  if (nDone == N_TODO) {
    return <>
      <Progress nDone={nDone} />
      All done! See you next week for round 2
      <div style={{height:100}}/>
      <hr/>
   <p>See the Ranking Round results here:{' '}
     <Link to = {`/${qtrId}/music/ratings`}>Ranking Results</Link>
  </p>
    </>
  }

  if (bracketToRate) {
    return <>
      <Progress nDone={nDone} />
      <CompareSongs
        bracketId={bracketToRate}
        eliminationData={eliminationData}
        songData={songData}
        roundId={roundId}
      />
    </>
  }

  return <>
    <Progress nDone={nDone} />
    <RequestComparisons roundId={roundId} />
  </>
}

const Progress = ({ nDone }) => {
  return <StyledProgressBar now={nDone} max={N_TODO} />
}

const CompareSongs = ({ bracketId, eliminationData, songData, roundId }) => {
  const user = useUser()
  const qtrId = useQuarterId()

  const [isSubmitting, setSubmitting] = useState(false)
  const [songOrder, setSongOrder] = useState(null)

  useEffect(() => {
    // randomize the order
    if (Math.random() > 0.5) {
      setSongOrder([1, 0])
    } else {
      setSongOrder([0, 1])
    }
  }, [])

  const submitVote = async (songId) => {
    setSubmitting(true)
    const token = await user.getIdToken(true)
    let URL = `https://us-central1-guia-cs109psets.cloudfunctions.net/submitSongComparisons`;
    const data = {
      token,
      qtrId,
      roundId,
      bracketId,
      songId
    }
    console.log("submitting", data)
    const response = await axios.post(URL, { data })
    setSubmitting(false)
    return response.data.result
  }

  const onClick = (song) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `You are voting for ${song.title} by ${song.artist}`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes, I prefer this song',
      cancelButtonText: 'No, Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        submitVote(song.id)
      }
    })
  }

  if (!songOrder) {
    return <></>
  }

  // tuple of songs to compare
  const bracketData = eliminationData[roundId][bracketId]

  // use the songOrder
  const song1 = songData[bracketData[songOrder[0]]]
  const song2 = songData[bracketData[songOrder[1]]]

  return <>
    <h2>Which song do you prefer?</h2>
    <SongsOuter>
      <SongOption song={song1} />
      <h2>OR</h2>
      <SongOption song={song2} />
    </SongsOuter>
    <hr />
    <ChoiceOuter>
      <button disabled={isSubmitting} className="btn btn-primary btn-lg" onClick={() => onClick(song1)}>I prefer {song1.title}</button>
      <button disabled={isSubmitting} className="btn btn-primary btn-lg" onClick={() => onClick(song2)}>I prefer {song2.title}</button>
    </ChoiceOuter>
  </>
}

const SongOption = ({ song }) => {
  return <>
    <SongTitle>{song.title} - {song.artist}</SongTitle>
    <SongLink
      songLink={song.link}
      artist={song.artist}
      title={song.title}
    />
    <Quote>{song.reason}</Quote>
  </>
}

const RequestComparisons = ({ roundId }) => {
  const qtrId = useQuarterId()
  const [isLoading, setLoading] = useState(true)
  let user = useUser()

  const querySongs = async () => {
    const response = await requestSongsToCompare(user, qtrId, roundId)
    setLoading(false)
  }

  useEffect(() => {
    querySongs()
  }, []);

  if (isLoading) {
    return <>Finding Songs to Compare...</>
  }

  return <></>
}

async function requestSongsToCompare(user, qtrId, roundId) {
  console.log("making request")
  const token = await user.getIdToken(true)
  let URL = `https://us-central1-guia-cs109psets.cloudfunctions.net/getSongsToCompare`;
  const response = await axios.post(URL, {
    data: {
      token,
      qtrId,
      roundId
    }
  })
  console.log("Response", response)
  return response.data.result
}

function getNumRated(roundVotes, userId) {
  let nDone = 0
  for (const voteKey in roundVotes) {
    const parts = voteKey.split('.')
    const voteUserId = parts[1]
    if (voteUserId == userId) {
      const voteData = roundVotes[voteKey]
      const isFilledIn = voteData?.vote !== undefined
      if (isFilledIn) {
        nDone += 1
      }
    }
  }
  return nDone
}

function getBracketToRate(roundVotes, userId) {
  // allVotes keys are <bracketId>.<userId>
  for (const voteKey in roundVotes) {
    // split on the .
    const parts = voteKey.split('.');
    const bracketId = parts[0];
    const voteUserId = parts[1];
    if (voteUserId == userId) {
      const voteData = roundVotes[voteKey]
      // is it filled in?
      const isFilledIn = voteData?.vote !== undefined
      if (!isFilledIn) {
        return bracketId
      }

    }
  }
  return null;
}

const SongsOuter = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  gap: 25px;
`

const ChoiceOuter = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 25px;
`

const StyledProgressBar = styled(ProgressBar)`
  margin-bottom: 20px;
  height: 25px;
`