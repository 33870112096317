import { Handout } from "components/handout/Handout"
import { RichTextEditor } from "components/richText/RichTextEditor"
import { useUser } from "contexts/UserContext"
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useQuarterId } from "hooks/UseQuarterId"
import { useStudentId } from "hooks/UseStudentId"
import { useDocumentData, useDocumentDataOnce } from "react-firebase-hooks/firestore"
import styled from "styled-components"
import DiscreteRadioButtons from "./DiscreteButton"
import { useContext, useEffect, useRef, useState } from "react"
import { FaCompress, FaExpand } from "react-icons/fa"
import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { PreviousNextButtons } from "./PreviousNextButtons"
import { PepTeacherContext } from "./ExamPrepTeacherContext"
import { Link } from "react-router-dom"
import Swal from "sweetalert2"
import { TranslationContext } from "contexts/TranslationContext"

export const ExamPrepTeacherPage = () => {
  return <HandoutOuter>
    <HandoutMain>
      <HandoutInner />
    </HandoutMain>
  </HandoutOuter>
}

const HandoutInner = () => {

  const { getT, translationData } = useContext(TranslationContext)

  const t = getT('pep')

  const scrollToRef = useRef(null)

  const psetId = 'pset3'

  const studentId = useStudentId()
  const qtrId = useQuarterId()
  const userId = useUser().uid

  const {
    signupsData,
    slotsData,
    classData,
    isPepTeacherContextLoading
  } = useContext(PepTeacherContext)

  const db = getFirestore();

  const psetDocPath = `users/${studentId}/${qtrId}/${psetId}`
  const taScoresPath = `personalexamprep/${qtrId}/midterm/students/${studentId}/private/ta/scores`
  const taNotesPath = `personalexamprep/${qtrId}/midterm/students/${studentId}/private/ta/notes`

  // load the pset completions before you show anything...
  const [psetCompletions, completionsLoading, completionsError] = useDocumentData(doc(db, psetDocPath));
  const [taScores, taScoresLoading, taScoresError] = useDocumentData(doc(db, taScoresPath));


  const [fullScreenState, setFullScreenState] = useState(null)

  useEffect(() => {
    if (fullScreenState === 'minimized') {
      console.log("scrolling to ref")
      scrollToRef.current.scrollIntoView()
    }
  }, [fullScreenState])

  if (isPepTeacherContextLoading||completionsLoading || taScoresLoading) {
    return <></>;
  }

  if (completionsError || taScoresError) {
    return <>Error</>
  }


  const completeDict = psetCompletions?.corrects
  const nComplete = completeDict ? Object.keys(completeDict).filter(k => completeDict[k]).length : 0
  const studentData = getStudentData(classData, studentId)


  const fullName = parseName(studentData)
  const firstName = fullName.split(' ')[0]

  

  const slotId = signupsData[studentId]
  const slotData = slotsData[slotId]

  const isFullScreenStudentWork = fullScreenState == 'full'

  

  return <>
    <div style={{ display: isFullScreenStudentWork ? 'none' : 'block' }}>
      <h1>{fullName}</h1>
      <h4>{studentData?.email}</h4>
      <MeetingInfo slotData={slotData} />
       <p><b>pset3 {t("done")}:</b> {nComplete} / 16</p>
      <PreMeetingQuestions />


      <hr />

      <h3>{t("Intro")} (1 min)</h3>
      <ul>
        <li>Hi you must be <b>{firstName}</b>, my name is _____. I am one of the TAs in CS109. 😊</li>
        <li>Welcome to pep.<br />I am going to ask you a few questions to help gauge where you are.<br />Then I will make you a personalised study plan:</li>
        <li>Just so you know: You are not graded on how well you answer questions today.<br />You get full participation points by showing up.</li>
        <li>We only get 10 mins so let's jump right in.</li>
        <li>{firstName}, why don't you start by telling me a little bit about yourself!</li>
      </ul>
      <hr />
      <h3>Warmup (2 min)</h3>
      <p><b>{firstName}</b>, Let's start with a problem. Here's a pencil and paper; walk me through how you would solve this problem</p>

      <p><b>Important</b> I want you to talk out loud as you are solving the problem! If you don't know a formula, that is fine, just tell me the one you are thinking of.</p>

      <div className="alert alert-primary mb-2">What is the probability that this function returns <b>True</b>

      </div>


      <PythonHighlighter code={`def warmup():
 coin_1 = coin_flip(0.6)    # returns 'Heads' with prob 0.6
 coin_2 = coin_flip(0.4)    # returns 'Heads' with prob 0.4
 return coin_1 == 'Heads' or coin_2 == 'Heads'`} fontSize={14} />

      <DiscreteRadioButtons
        dbDocData={taScores}
        dbDocPath={taScoresPath}
        docKey="warmupQuestion"
      />
      <hr />

      <h3 ref={scrollToRef}>Review Solution (1 min)</h3>

      <ReviewSolutionIntro 
        firstName={firstName}
        completeDict={completeDict}
      />

      

      <div className="alert alert-primary mb-2">A flight has <b>280 seats</b>.<br />
        The airline sells <b>340 tickets</b> for that same flight.<br />
        Each passenger shows up for the flight with <b>probability 0.85</b><br />
        What is the probability there <b>are enough seats</b> for the passengers who show up?

      </div> 

    </div>

    <button className="btn btn-secondary btn-small" onClick={() => setFullScreenState(isFullScreenStudentWork ? 'minimized' : 'full')}>
        {isFullScreenStudentWork ? <FaCompress /> : <FaExpand />}
      </button><br />

    <StudentAssnWork
      psetId='pset3'
      qId='airlines'
      fullScreen={isFullScreenStudentWork}
    />

    <div  style={{ display: isFullScreenStudentWork ? 'none' : 'block' }}>
      <PSetProblemButtons 
        dbDocData={taScores}
        dbDocPath={taScoresPath}
        docKey="reviewSolutionStatus"
        completeDict={completeDict}
      />

      <DiscreteRadioButtons
        dbDocData={taScores}
        dbDocPath={taScoresPath}
        docKey="reviewSolution"
      />
      <hr />

      <h3>Gaussian Approx (2 min)</h3>
      <p>Fantastic <b>{firstName}</b>. I am going to add a few twists. First! Lets use a Gaussian approximation.</p>

      <div className="alert alert-primary">
        For faster computation, you want to approximate your random variable for the number of people who show up as a Gaussian. What is the mean of the approximating Gaussian? (if you are really ahead on time: walk me through the remaining steps to approximate the probability that there are enough seats)</div>

      <DiscreteRadioButtons
        dbDocData={taScores}
        dbDocPath={taScoresPath}
        docKey="gaussianApprox"
      />
      <hr />

      <h3>Bayesian Inference (2 min)</h3>

      <p><b>{firstName}</b> this is the last one. This is meant to be hard. Give it your best, but don’t worry if you find it difficult right now.</p>

      <p><b>Important</b> Today all that I we care about is that you can set up the problem. You don't have to solve it.</p>

      <p><b>Note for TA</b> I imagine in about 5-10% of cases you will need to skip this problem. I believe that will mostly be for students who really struggled with Warmup or Review Solution. In that case I defer to your good judgement. Focus more on their questions and a pep talk (though even in that case it might be nice to show them the hard problem). You may show them how to solve the problem. </p>

      <div className="alert alert-primary">
        You are a passenger and the number of tickets sold by the airline is unknown.<br />
        You observe that <b>300 people</b> show up for the flight.<br />
        What is your belief that the airline <b>sold k tickets</b>?<br />
        Each person still shows up with probability <b>0.85</b>.<br />
        Your prior belief in the number of tickets told is <b>Bin(350, 0.8)</b>.<br />
      </div>



      <DiscreteRadioButtons
        dbDocData={taScores}
        dbDocPath={taScoresPath}
        docKey="inference"
      />

      <hr />

      <h3>Outro (1 min)</h3>

      <p>Thank you so much <b>{firstName}</b>. That was fast paced. I appreciate you putting this extra time into getting ready.</p>
      
      <p>I know that we just covered inference formally on Friday. We chose to highlight it becase it is often the problem that has the highest variance in scores.</p>

      <p>I am going to send you your personal exam prep summary by the end of the day.</p>
      
      <p>I have someone coming right now. Thank you so much and good luck getting ready for the midterm. The teaching team is rooting for you. </p>

      <PublishButton dbDocData={taScores} dbDocPath={taScoresPath}/>


      <hr/>
      <h3>Internal Insights (not seen by {firstName})</h3>
      <RichTextEditor
        firebaseDocPath={taNotesPath}
        collaborative={true}
        editable={true}
        user={userId}
      />
      <a target="_blank" href={`https://docs.google.com/document/d/1JL5POv9j0E7cJts-mP-LNRflak6i4HPbctCNB2Ho20Y/edit?usp=sharing`}>Link to shared TA notes Google Doc</a><br/>
      <Link target="_blank" to = {`/${qtrId}/personalprep/midterm/guide/${studentId}`}>{firstName}'s Guide</Link>
      <hr/>
      <PreviousNextButtons />
    </div>
  </>
}

const MeetingInfo = ({ slotData }) => {

  if (!slotData) {
    return <></>
  }
  return <>
    
      <p>{slotData.date} at {slotData.time} in {slotData.location}<br/>
      TA: {slotData.ta}</p>
      <hr />
  </>
}

const PublishButton = ({ dbDocData, dbDocPath }) => {
  const isPublished = dbDocData?.isPublished

  const onClick = () => {
    const newState = !isPublished

    if(newState == false) {
      Swal.fire({
        title: 'Are you sure?',
        text: "This will unpublish the guide and the student will no longer be able to see it.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, unpublish it!'
      }).then((result) => {
        if (result.isConfirmed) {
          setDoc(doc(getFirestore(), dbDocPath), { isPublished: newState }, { merge: true });
        }
      })
    } else {
      setDoc(doc(getFirestore(), dbDocPath), { isPublished: newState }, { merge: true });
    }
  }

  const text = isPublished ? 'Unpublish' : 'Publish' + ' Guide'
  return <>
  <button onClick={() => onClick()}className="btn btn-primary">{text}</button>
  </>
}

const ReviewSolutionIntro = ({ firstName, completeDict }) => {
  const reviewProblemComplete = completeDict?.airlines

  if(!reviewProblemComplete) {
    return <><p><b>{firstName}</b>, This next problem starts with solving problem 2 on PSet3. In case its helpful, about 51% of the class has had a chance to solve this problem. I strongly recommend that you find time to finish this last PSet by the Friday 2pm deadline.</p>
    <p>Lets feed two birds with one scone. Here is the problem:</p>
    </>


  }

  return <p><b>{firstName}</b>, let's move on to the next question. The next few problems are going to build off one of the questions on your PSet. To warmup, remind yourself of the problem and walk me through your solution</p>
}

const PreMeetingQuestions = () => {
  return <>
    <PreMeetingQuestion
      qText="What sort of advice from a TA would help you most?"
      qId="whatAdvice"
    />
    <PreMeetingQuestion
      qText="How has learning CS109 material been going for you so far?"
      qId="howsLearningGoing"
    />
    <PreMeetingQuestion
      qText="How are you planning to study for the midterm?"
      qId="howStudy"
    />


    <PreMeetingQuestion
      qText="When you get stuck on a pset problem, what do you do?"
      qId="stuckOnPSet"
    />
  </>
}

const PSetProblemButtons = ({ completeDict, dbDocData, dbDocPath, docKey }) => {
  const value = dbDocData?.[docKey]
  const db = getFirestore();
  const valueToText = {
    'notStarted': 'Not Started',
    'started': 'Started',
    'complete': 'Finished'
  }

  useEffect(() => {
    if(!value) {
      // if the problem is complete, set the status to complete
      if(completeDict?.airlines) {
        setDoc(doc(db, dbDocPath), { [docKey]: 'complete' }, { merge: true });
      }
    }
  }, [value])
  
  // three buttons: not started, started, complete
  return <div className="d-flex flex-row" style={{gap:20}}>
    {['notStarted', 'started', 'complete'].map((v) => {
      const color = value === v ? 'btn-outline-primary' : 'btn-outline-secondary'
      return <button
        key={v}
        className={`btn ${color}`}
        onClick={() => {
          const newValue = value === v ? null : v
          setDoc(doc(db, dbDocPath), { [docKey]: newValue }, { merge: true });
        }}
      >
        {valueToText[v]}
      </button>
    })}
  </div>
}


const PreMeetingQuestion = ({ qId, qText }) => {
  const studentId = useStudentId()
  const qtrId = useQuarterId()
  const userId = useUser().uid
  const firebaseDocPath = `personalexamprep/${qtrId}/midterm/students/${studentId}/${qId}`
  return <>
    <b>{qText}</b>
    <RichTextEditor
      user={userId}
      editable={false}
      firebaseDocPath={firebaseDocPath}
      minHeight={70}
      collaborative={true}
    />
  </>
}


const StudentAssnWork = ({ psetId, qId, fullScreen }) => {
  const studentId = useStudentId()
  const qtrId = useQuarterId()
  const userId = useUser().uid
  const firebaseDocPath = `/users/${studentId}/${qtrId}/${psetId}/answers/${qId}`

  const editerOuterStyle = fullScreen ? {
    flex: 1,
    flexGrow: 1,
    height: '100px',
    display: 'flex',
    maxWidth: '100vw',
  } : {
    marginBottom: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '5px'
  }

  const innerStyle = fullScreen ? {
    maxWidth: 670,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  } : {}

  const outerStyle = fullScreen ? {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    height: 'calc(100vh - 100px)',

  } : {}

  return <div style={outerStyle}>
    <div style={innerStyle}>
      {fullScreen ? <><h3>Airlines and Overselling Flights</h3><hr /></> : <b>Student answer:</b>}

      <div style={{ display: fullScreen ? 'block' : 'none' }}>
        <b>The question:</b>
        <div className="alert alert-primary mb-2">A flight has <b>280 seats</b>.<br />
          The airline sells <b>340 tickets</b> for that same flight.<br />
          Each passenger shows up for the flight with <b>probability 0.85</b><br />
          What is the probability there <b>are enough seats</b> for the passengers who show up?

        </div>
        <b>Your answer:</b>
      </div>

      <div style={editerOuterStyle}>
        <RichTextEditor
          user={userId}
          editable={false}
          firebaseDocPath={firebaseDocPath}
          collaborative={true}
          minHeight={300}
        />
      </div>
    </div>
  </div>
}

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

function getStudentData(classData, studentId) {
  // classData.students is an array of objects
  const students = classData.students
  if (!students) return null
  for (const student of students) {
    if (student.uid === studentId) {
      return student
    }
  }
}

function parseName(studentData) {
  // names are written like this <FamilName>,<GivenName> <MiddleName>
  // we want to display them like <GivenName> <MiddleName> <FamilyName>
  if (!studentData) return ''
  const name = studentData.name
  const parts = name.split(',')
  if (parts.length === 1) return name
  const [familyName, givenName] = parts
  const givenParts = givenName.split(' ')
  const givenNameParts = givenParts.map(toTitleCase)
  return `${givenNameParts.join(' ')} ${familyName}`
}


const HandoutOuter = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
`

const HandoutMain = styled.div`
  max-width: 670px;
`

