import { useQuarterId } from "hooks/UseQuarterId"
import { useStudentId } from "hooks/UseStudentId"
import { useContext } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { PepTeacherContext } from "./ExamPrepTeacherContext"
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"

export const PreviousNextButtons = () => { 
  
  const {
    slotsData, 
    signupsData, 
    slotToStudent,
    isPepTeacherContextLoading
  } = useContext(PepTeacherContext)

  const studentId = useStudentId()

  const qtrId = useQuarterId()


  if (isPepTeacherContextLoading){
    return <></>
  }

  const slotId = signupsData[studentId]
  const slotData = slotsData[slotId]

  if(!slotData){
    return <></>
  }

  const ta = slotData.ta
  const slotsWithTa = getSlotsWithTa(slotsData, slotToStudent, ta)

  const prevSlot = getPreviousSlot(slotsWithTa, slotData)
  const prevStudent = slotToStudent?.[prevSlot]

  const nextSlot = getNextSlot(slotsWithTa, slotData)
  const nextStudent = slotToStudent?.[nextSlot]

  console.log(prevSlot)
  console.log(prevStudent)

  return <ButtonRow>
    
    {prevSlot ? <Link  className="btn btn-primary" to = {`/${qtrId}/personalprep/midterm/ta/${prevStudent}`}>
      <FaArrowLeft/>
    
    </Link>: <>Start</>}
    <Link className="btn btn-secondary" to = {`/${qtrId}/personalprep/midterm/ta`}>
      Home
    </Link>
    {nextSlot ? <Link className="btn btn-primary" to = {`/${qtrId}/personalprep/midterm/ta/${nextStudent}`}>
      <FaArrowRight/>
    </Link> : <>End</>}

  </ButtonRow>
}

const getPreviousSlot = (allSlots, currSlot) => {
  // Convert date and time strings to Date objects for comparison
  const parseSlot = (slot) => {
    return new Date(`${slot.date} ${slot.time}`);
  };

  // Parse the current slot
  const currDateTime = parseSlot(currSlot);

  // Filter slots that are before the current slot
  const previousSlots = allSlots
    .filter(slot => parseSlot(slot) < currDateTime)
    .sort((a, b) => parseSlot(b) - parseSlot(a)); // Sort by date in descending order

  // Return the slotId of the closest previous slot
  return previousSlots.length > 0 ? previousSlots[0].slotId : null;
};

const getNextSlot = (allSlots, currSlot) => {
  // Convert date and time strings to Date objects for comparison
  const parseSlot = (slot) => {
    return new Date(`${slot.date} ${slot.time}`);
  };

  // Parse the current slot
  const currDateTime = parseSlot(currSlot);

  // Filter slots that are after the current slot
  const nextSlots = allSlots
    .filter(slot => parseSlot(slot) > currDateTime)
    .sort((a, b) => parseSlot(a) - parseSlot(b)); // Sort by date in ascending order

  // Return the slotId of the closest next slot
  return nextSlots.length > 0 ? nextSlots[0].slotId : null;
};



const getSlotsWithTa = (slotsData, slotToStudent, ta) => {
  const slotsWithTa = []
  for (const slotId in slotsData){
    if(slotsData[slotId].ta === ta){
      // next check if a student has signed up for this slot
      if (slotToStudent[slotId]){

        const newSlotsData = {...slotsData[slotId], slotId}
        slotsWithTa.push(newSlotsData)
      }
    }
  }
  return slotsWithTa
}


const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 20px;
`