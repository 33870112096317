import { useUser } from "contexts/UserContext"
import { doc, getFirestore, updateDoc } from "firebase/firestore"
import { useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore"
import Swal from "sweetalert2";
import { SlotSelection } from "./SlotSelection";
import styled from "styled-components";
import { SelectedSlot } from "./SelectedSlot";
import { functions } from "firebaseApp";
import axios from "axios";
import { useQuarterId } from "hooks/UseQuarterId";

export const TimeSignup = ({ slotsPath, signupsPath, editable }) => {
  return <TimeSignupOuter>
    <h2>Reserve a Time</h2>
    <TimeSignupInner 
      slotsPath={slotsPath} 
      signupsPath={signupsPath} 
      editable={editable}
    />
  </TimeSignupOuter>
}

const TimeSignupInner = ({ slotsPath, signupsPath, editable }) => {
  const db = getFirestore();
  const [slotsData, slotsLoading, slotsError] = useDocumentData(doc(db, slotsPath));
  const [signupsData, signupsLoading, signupsError] = useDocumentData(doc(db, signupsPath));
  const [signupRequestInProgress, setSignupRequestInProgress] = useState(false);

  const user = useUser();
  const userId = user.uid;

  const qtrId = useQuarterId()
  const examId = 'midterm'

  if (slotsLoading || signupsLoading) {
    return <></>;
  }

  if (slotsError || signupsError) {
    console.error("slotsError", slotsError);
    console.error("signupsError", signupsError);
    return <p>Error 500</p>;
  }

  const availableSlots = computeAvailibleSlots(slotsData, signupsData);
  const selectedSlot = signupsData[userId];

  const handleSlotSelection = async (slotId) => {
    try {
      setSignupRequestInProgress(true);
      const token = await user.getIdToken(true)
      let URL = `https://us-central1-guia-cs109psets.cloudfunctions.net/onSignupRequest`;
      const response = await axios.post(URL, {
        data: {
          token: token,
          qtrId: qtrId,
          examId: examId,
          slotId: slotId
        }
      })
      console.log("response", response)

    } catch (error) {
      console.error("Error updating slot", error);
    }
    setSignupRequestInProgress(false);
  };

  if (signupRequestInProgress) {
    // return a bootstrap spinner
    return <div className="spinner-grow text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  }

  if (selectedSlot) {
    return <SelectedSlot
      selectedId={selectedSlot}
      slotsData={slotsData}
      handleSlotSelection={handleSlotSelection}
    />
  }

  // if(!editable) {
  //   return <p>Personal Exam Prep have started and the signups are closed. If PEP goes well we will do something similar for the final. </p>
  // }

  

  return <SlotSelection
    availableSlots={availableSlots}
    handleSlotSelection={handleSlotSelection}
  />
};





const computeAvailibleSlots = (slotsData, signupsData) => {
  // which slotIds are already used?
  const usedSlotIds = new Set(Object.values(signupsData))

  // dictionary from time to each free slotId at that time
  const availibleSlots = {}
  for (const slotId in slotsData) {

    // nobody has claimed this slot yet
    const isAvailable = !usedSlotIds.has(slotId)

    if (isAvailable) {
      const slotData = slotsData[slotId]
      const time = slotData.time
      const date = slotData.date
      const dateTime = `${date} ${time}`
      if (!availibleSlots[dateTime]) {
        availibleSlots[dateTime] = []
      }
      availibleSlots[dateTime].push(slotId)
    }
  }
  return availibleSlots
}

const TimeSignupOuter = styled.div`
  max-width: 670px;

  // min-height: 210px;
  // border: 1px solid #ccc;
  // padding: 20px;
  // border-radius: 10px;
  `